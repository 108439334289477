import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '@codenteam/core/routes';
import { NotificationType, ExtraData } from '@codenteam/portal/graphql';

@Injectable({
  providedIn: 'root',
})
export class NotificationCoreService {
  constructor(private router: Router, private routesService: RoutesService) {}

  private notificationRoutes: { [key: string]: (extra: ExtraData) => void } = {
    RunCompletedNotification: (extra) => {
      return this.router.navigate(this.routesService.runHome(extra.uuid));
    },
    RunSharedNotification: (extra) =>
      this.router.navigate(this.routesService.runHome(extra.uuid)),
    DastCompletedNotification: (extra) =>
      this.router.navigate(this.routesService.scanItem(extra.uuid)),
  };

  navigate(notification: NotificationType) {
    const { type, data } = notification;
    const navigateFn = this.notificationRoutes[type];

    if (navigateFn && data.extra?.uuid) {
      navigateFn(data.extra);
    } else {
      console.warn(`No route defined for notification type: ${type}`);
    }
  }
  getTimeDifference(createdAt: Date): string {
    const now = new Date();
    const diffMs = now.getTime() - new Date(createdAt).getTime();
    const seconds = Math.floor(diffMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days < 7) {
      return `${days} days ago`;
    } else if (weeks < 4) {
      return `${weeks} weeks ago`;
    } else if (months < 12) {
      return `${months} months ago`;
    } else {
      return `${years} years ago`;
    }
  }
}
