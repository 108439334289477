<mat-toolbar class="flex dark:bg-slate-700">
  <button
    mat-icon-button
    *ngrxLet="this.sidenavService.sidenavExpanded$ | async"
    (click)="toggleSide = !toggleSide"
    class="flex"
  >
    <mat-icon class="dark:text-blue-400">menu</mat-icon>
  </button>
  <!-- desktop -->
  <div class="hidden flex-1 lg:flex">
    <a
      class="ml-2 flex"
      (click)="gotoHomePage()"
    >
      <img
        class="brand w-32 cursor-pointer object-contain text-xl"
        data-test="logo-top"
        src="../../../assets/logo.png"
      />
    </a>

    <codenteam-search-form class="mx-10 flex flex-1"></codenteam-search-form>
    <div class="ml-auto flex items-center justify-between pr-10">
      <div class="mb-0 ml-4 flex">
        <div class="hidden lg:block">
          <p class="text-xs text-gray-500 dark:text-gray-500">Account</p>
          <p class="text-sm dark:text-white">{{ name }}</p>
          <p class="text-xs text-gray-500 dark:text-gray-400">
            {{ email }}
          </p>
        </div>
        <div class="ml-auto content-center">
          <div
            class="relative ml-20 h-10 w-10 overflow-hidden rounded-full bg-gray-300 text-gray-600"
          >
            <mat-icon
              class="!h-10 !w-10"
              svgIcon="single-developer"
            ></mat-icon>
          </div>
        </div>
        <mat-icon
          [matMenuTriggerFor]="menu"
          class="ml-3 mt-3 cursor-pointer text-gray-600 dark:text-white"
          >keyboard_arrow_down</mat-icon
        >
        <mat-menu
          #menu="matMenu"
          class="!bg-slate-800"
        >
          <button
            mat-menu-item
            (click)="logOut()"
            data-test="logout-button"
          >
            Log Out
          </button>
        </mat-menu>
        <button
          [matMenuTriggerFor]="notificationsMenu"
          (menuOpened)="isMenuOpen = true"
          (menuClosed)="isMenuOpen = false"
          *ngIf="'notifications' | featureflag$ | async"
        >
          <div
            matBadge="{{ unreadNotificationsCount$ | async }}"
            matBadgeHidden="{{
              (unreadNotificationsCount$ | async) === 0 || isMenuOpen
            }}"
            matBadgeSize="small"
            class="custom-badge round relative ml-3 h-10 w-10 place-content-center place-items-center overflow-hidden rounded-full py-2 text-slate-800"
            [ngClass]="
              isMenuOpen ? 'bg-blue-400 ' : 'bg-slate-600 hover:bg-slate-500'
            "
          >
            <mat-icon>notifications</mat-icon>
          </div>
        </button>
        <mat-menu
          #notificationsMenu="matMenu"
          class="!max-w-[400px] !rounded-xl !bg-slate-600 px-[18px] py-3"
          ><div class="font-mada mb-1 text-2xl font-semibold text-slate-900">
            Notifications
          </div>
          <ng-container *ngIf="notifications$ | async as notifications">
            <div
              class="font-mada flex h-40 w-[350px] items-center justify-center text-2xl text-gray-400"
              *ngIf="notifications?.length === 0"
            >
              No Notifications Yet
            </div>
            <div
              class="flex max-h-[400px] flex-col overflow-y-auto"
              *ngIf="notifications.length > 0"
            >
              <div
                class="font-mada flex flex-col text-white"
                *ngFor="let notification of notifications"
              >
                <button
                  class="my-1 rounded-lg px-2 py-2 hover:bg-slate-500"
                  (click)="notificationNavigate(notification)"
                >
                  <div class="flex items-center justify-between">
                    <div class="ml-2 text-xs text-zinc-400">
                      {{ getTimeDifference(notification.createdAt) }}
                    </div>
                    <mat-icon
                      class="mt-0.5 !text-sm text-blue-400"
                      *ngIf="!notification.readAt"
                      >circle</mat-icon
                    >
                  </div>
                  <div class="text-start text-[17px] font-medium">
                    {{ notification.data.title }}
                  </div>
                  <div class="text-start text-sm leading-[19px]">
                    {{ notification.data.message }}
                  </div>
                </button>
                <mat-divider class="text-blue-400"></mat-divider>
              </div>
            </div>
            <div
              class="font-mada mt-3 flex justify-between text-sm font-medium leading-[18px] text-slate-800"
              *ngIf="notifications.length > 0"
            >
              <!-- <button class="underline underline-offset-4 hover:text-slate-900">
              Show more</button
            > -->
              <button
                class="underline underline-offset-4 hover:text-slate-900"
                (click)="clearNotifications()"
              >
                Clear All
              </button>
            </div>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div class="flex flex-1 lg:hidden">
    <a
      class="flex flex-1"
      (click)="gotoHomePage()"
      *ngIf="!searchExpand"
    >
      <img
        class="brand w-32 cursor-pointer object-contain text-xl"
        src="../../../assets/logo.png"
      />
    </a>
    <codenteam-search-form
      *ngIf="searchExpand"
      class="flex w-28 flex-1"
    ></codenteam-search-form>

    <button
      mat-icon-button
      color="primary"
      (click)="searchExpand = !searchExpand"
    >
      <mat-icon>{{ !searchExpand ? 'search' : 'close' }}</mat-icon>
    </button>

    <div class="flex items-center justify-between">
      <div class="mb-0 ml-4 flex">
        <div class="hidden lg:block">
          <p class="text-xs text-gray-500 dark:text-gray-500">Account</p>
          <p class="text-sm dark:text-white">{{ name }}</p>
          <p class="text-xs text-gray-500 dark:text-gray-400">
            {{ email }}
          </p>
        </div>
        <div>
          <div
            class="relative h-10 w-10 overflow-hidden rounded-full bg-gray-300 text-gray-600"
          >
            <svg
              class="absolute h-12 w-12 pr-2 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <mat-icon
          [matMenuTriggerFor]="menu"
          class="ml-3 mt-2 cursor-pointer text-gray-600 dark:text-white"
          data-test="down-arrow"
          >keyboard_arrow_down</mat-icon
        >
        <mat-menu
          #menu="matMenu"
          class="!bg-slate-800"
        >
          <button
            mat-menu-item
            (click)="logOut()"
            data-test="logout-button"
          >
            Log Out
          </button>
        </mat-menu>
        <button
          [matMenuTriggerFor]="notificationsSmallMenu"
          (menuOpened)="isMenuOpen = true"
          (menuClosed)="isMenuOpen = false"
          *ngIf="'notifications' | featureflag$ | async"
        >
          <div
            matBadge="{{ unreadNotificationsCount$ | async }}"
            matBadgeHidden="{{
              (unreadNotificationsCount$ | async) === 0 || isMenuOpen
            }}"
            matBadgeSize="small"
            class="custom-badge round relative ml-3 h-10 w-10 place-content-center place-items-center overflow-hidden rounded-full py-2 text-slate-800"
            [ngClass]="
              isMenuOpen ? 'bg-blue-400 ' : 'bg-slate-600 hover:bg-slate-500'
            "
          >
            <mat-icon>notifications</mat-icon>
          </div>
        </button>
        <mat-menu
          #notificationsSmallMenu="matMenu"
          class="!max-w-[250px] !rounded-xl !bg-slate-600 px-[18px] py-3 sm:!max-w-[400px]"
          ><div class="font-mada mb-1 text-xl font-semibold text-slate-900">
            Notifications
          </div>
          <ng-container *ngIf="notifications$ | async as notifications">
            <div
              class="font-mada flex h-40 w-[200px] items-center justify-center text-xl text-gray-400 sm:w-[350px] sm:text-2xl"
              *ngIf="notifications?.length === 0"
            >
              No Notifications Yet
            </div>
            <div
              class="flex max-h-[400px] flex-col overflow-y-auto"
              *ngIf="notifications.length > 0"
            >
              <div
                class="font-mada flex flex-col text-white"
                *ngFor="let notification of notifications"
              >
                <button
                  class="my-1 rounded-lg px-2 py-2 hover:bg-slate-500"
                  (click)="notificationNavigate(notification)"
                >
                  <div class="flex items-center justify-between">
                    <div class="ml-2 text-xs text-zinc-400">
                      {{ getTimeDifference(notification.createdAt) }}
                    </div>
                    <mat-icon
                      class="mt-0.5 !text-sm text-blue-400"
                      *ngIf="!notification.readAt"
                      >circle</mat-icon
                    >
                  </div>
                  <div class="text-start text-sm leading-[19px]">
                    {{ notification.data.message }}
                  </div>
                </button>
                <mat-divider class="text-blue-400"></mat-divider>
              </div>
            </div>
            <div
              class="font-mada mt-3 flex justify-between text-sm font-medium leading-[18px] text-slate-800"
              *ngIf="notifications.length > 0"
            >
              <!-- <button class="underline underline-offset-4 hover:text-slate-900">
            Show more</button
          > -->
              <button
                class="underline underline-offset-4 hover:text-slate-900"
                (click)="clearNotifications()"
              >
                Clear All
              </button>
            </div>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
<mat-sidenav-container
  class="block h-[calc(100%-64px)] w-full dark:bg-slate-900"
  autosize
>
  <mat-sidenav
    #navbar
    mode="side"
    opened="true"
    class="flex w-auto border-0 dark:bg-slate-900"
    [ngClass]="toggleSide ? '' : 'collapsed max-w-0 md:max-w-[60px]'"
  >
    <div class="flex h-full flex-col">
      <mat-accordion class="app-nav-accordion">
        <mat-nav-list
          class="flex flex-1 pb-0"
          [ngClass]="toggleSide ? 'w-60' : 'w-15'"
        >
          <mat-list-item
            *ngFor="let link of sideNavMenu"
            [ngClass]="runId ? '' : '!hidden'"
            (click)="navigateToUrl(link.path)"
            attr.data-test="{{ link.dataTest }}"
          >
            <mat-icon
              matListItemIcon
              *ngIf="runId"
              matTooltip="{{ link.name }}"
              class="dark:!text-blue-400"
              >{{ link.icon }}</mat-icon
            >
            <a
              matListItemTitle
              *ngIf="runId && toggleSide"
              >{{ link.name }}</a
            >
          </mat-list-item>

          <mat-expansion-panel
            class="mat-elevation-z0"
            *ngIf="teams && teams.length > 0"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="!text-blue-400"
                ><mat-icon svgIcon="team"></mat-icon
                ><span class="header-text !text-white"
                  >Teams</span
                ></mat-panel-title
              >
            </mat-expansion-panel-header>
            <mat-nav-list class="border-b border-solid border-blue-400">
              <mat-list-item
                *ngFor="let team of teams"
                trackClick="choose-team-from-sidenav"
                (click)="gotoSingleTeamAnalysis(team)"
              >
                <mat-icon
                  matListItemIcon
                  svgIcon="team"
                  [style.color]="team.color + ' !important'"
                  matTooltip="{{ team.name }}"
                ></mat-icon>

                <a
                  matListItemTitle
                  *ngIf="toggleSide"
                  >{{ team.name }}</a
                >
              </mat-list-item>
              <mat-list-item
                *ngIf="runId && canEdit"
                (click)="goToEditTeams()"
                [matTooltip]="
                  isProfileNotAssigned
                    ? 'You have unassigned profiles'
                    : 'Edit Teams'
                "
              >
                <mat-icon
                  matListItemIcon
                  class="relative dark:!text-blue-400"
                  svgIcon="edit-teams"
                  ><div
                    class="absolute right-0 top-0 h-3 w-3 rounded-full bg-red-500"
                    *ngIf="isProfileNotAssigned"
                  ></div
                ></mat-icon>
                <a
                  matListItemTitle
                  *ngIf="runId && toggleSide"
                  >Edit Teams</a
                >
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>
          <ng-container
            *ngIf="
              ('organizations' | featureflag$ | async) === true &&
              runId &&
              (organizations$ | async) as organizations
            "
          >
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header class="text-">
                <mat-panel-title class="!text-blue-400"
                  ><mat-icon>public</mat-icon
                  ><span class="header-text !text-white"
                    >Organizations</span
                  ></mat-panel-title
                >
              </mat-expansion-panel-header>
              <mat-nav-list class="border-b border-solid border-blue-400">
                <mat-list-item
                  *ngFor="let organization of organizations"
                  (click)="gotoSingleOrganizationAnalysis(organization.id)"
                >
                  <mat-icon
                    matListItemIcon
                    [style.color]="organization.color + ' !important'"
                    matTooltip="{{ organization.name }}"
                    >public</mat-icon
                  >
                  <a
                    matListItemTitle
                    *ngIf="toggleSide"
                    >{{ organization.name }}</a
                  >
                </mat-list-item>
              </mat-nav-list>
            </mat-expansion-panel>
          </ng-container>
          <mat-list-item
            *ngIf="runId && canEdit"
            (click)="goToEditProfiles()"
            [matTooltip]="
              isAuthorNotAssigned
                ? 'You have unassigned authors'
                : 'Edit Profiles'
            "
          >
            <mat-icon
              matListItemIcon
              class="relative dark:!text-blue-400"
              svgIcon="edit-profiles"
              ><div
                class="absolute right-0 top-0 h-3 w-3 rounded-full bg-red-500"
                *ngIf="isAuthorNotAssigned"
              ></div
            ></mat-icon>
            <a
              matListItemTitle
              *ngIf="runId && toggleSide"
              >Edit Profiles</a
            >
          </mat-list-item>
          <mat-list-item
            *ngIf="teams.length !== 0"
            (click)="goToReportPage()"
            data-test="code-analysis-report"
          >
            <mat-icon
              matListItemIcon
              matTooltip="Report"
              svgIcon="report-icon"
              class="dark:!text-blue-400"
            ></mat-icon>
            Report
          </mat-list-item>
          <mat-divider *ngIf="teams.length !== 0"></mat-divider>
          <mat-list-item
            *ngIf="'project-hub' | featureflag$ | async"
            (click)="goToProjectsHubPage()"
          >
            <mat-icon
              matListItemIcon
              matTooltip="Projects"
              class="!text-blue-400"
              svgIcon="project-hub"
            ></mat-icon>
            Projects
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item (click)="gotoRunsList()">
            <mat-icon
              matListItemIcon
              matTooltip="My Runs"
              class="!text-blue-400"
              svgIcon="my-runs"
            ></mat-icon>
            My Runs
          </mat-list-item>
          <mat-list-item
            (click)="gotoSharedRunsList()"
            *ngIf="('share-runs' | featureflag$ | async) === true"
          >
            <mat-icon
              matListItemIcon
              matTooltip="Shared With Me"
              class="!text-blue-400"
              svgIcon="shared-with-me"
            ></mat-icon>
            Shared With Me
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item
            *ngIf="'hiring-jobs' | featureflag$ | async"
            (click)="goToHiringJobListingPage()"
            trackClick="hiring-bttn-sidenav"
          >
            <mat-icon
              matListItemIcon
              matTooltip="Hiring"
              svgIcon="hiring-new-icon"
              class="dark:!text-blue-400"
            ></mat-icon>
            Hiring
          </mat-list-item>
          <mat-list-item
            *ngIf="'scans' | featureflag$ | async"
            (click)="gotoScans()"
          >
            <mat-icon
              matListItemIcon
              matTooltip="Scans"
              class="dark:!text-blue-400"
              >security</mat-icon
            >
            Scans
          </mat-list-item>
          <mat-list-item
            *ngIf="'investor-hub' | featureflag$ | async"
            (click)="goToInvestorHubPage()"
          >
            <mat-icon
              matListItemIcon
              matTooltip="Investors Hub"
              class="!text-blue-400"
              >rocket</mat-icon
            >
            Investors Hub
          </mat-list-item>

          <mat-list-item
            matTooltip="Report"
            (click)="gotoReport()"
            *ngIf="('report' | featureflag$ | async) === true"
          >
            <mat-icon
              matListItemIcon
              class="dark:!text-blue-400"
              >assignment</mat-icon
            >
            <a
              matListItemTitle
              routerLink="/"
              *ngIf="toggleSide"
              >Reports Center</a
            >
          </mat-list-item>
          <mat-divider></mat-divider>

          <mat-list-item
            matTooltip="Settings"
            (click)="gotoAccountUsersPage()"
          >
            <mat-icon
              matListItemIcon
              class="dark:!text-blue-400"
              >settings</mat-icon
            >
            <a
              matListItemTitle
              routerLink="/"
              *ngIf="toggleSide"
              >Setting</a
            >
          </mat-list-item>
          <mat-list-item
            matTooltip="Start tour"
            data-test="start-tour"
            (click)="startTour()"
          >
            <mat-icon
              matListItemIcon
              class="dark:!text-blue-400"
              >help</mat-icon
            >
            <a
              matListItemTitle
              routerLink="/"
              *ngIf="toggleSide"
              >Help</a
            >
          </mat-list-item>
        </mat-nav-list>
      </mat-accordion>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    class="back py-3 pl-0 pr-0 md:pl-3 md:pr-2"
    [class.opened]="toggleSide"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
